import { notification } from 'antd';
import { getOrders } from 'api/orders';
import sound from 'assets/sounds/notif.mp3';
import { NewOrdersContext } from 'constants/contexts';
import { PERMISSIONS } from 'constants/permissions';
import * as orderStatus from 'constants/status';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useInterval } from 'react-use';
import { isOrderStatus } from 'utils/orders';

function NewOrdersProvider({ children }) {
  const [lastOrderIds, setLastOrderIds] = useState([]);
  const [newOrderIds, setNewOrderIds] = useState([]);
  const [lastScheduledOrderIds, setLastScheduledOrderIds] = useState([]);
  const [newScheduledOrderIds, setScheduledNewOrderIds] = useState([]);
  const firstFetchRef = useRef(true);
  const firstScheduledFetchRef = useRef(true);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const soundRef = useRef();
  const hasOrderAccess = useHasAnyOfPermissions([PERMISSIONS.ORDERS]);
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);

  const setSeen = useCallback((id) => {
    setNewOrderIds((ids) => {
      if (!ids.includes(id)) {
        return ids;
      }
      return ids.filter((i) => i !== id);
    });
    setScheduledNewOrderIds((ids) => {
      if (!ids.includes(id)) {
        return ids;
      }
      return ids.filter((i) => i !== id);
    });
  }, []);

  useInterval(
    () => {
      getOrders({
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ordering: '-is_new,-timestamp',
        state__in: [
          orderStatus.NEW,
          isAdmin && orderStatus.NEEDS_APPROVAL,
        ].filter(isOrderStatus),
        page: 1,
        page_size: 5,
        only_today: 1,
      })
        .then(({ data: { data } }) => {
          const { results } = data;
          const orderIds = results.map((o) => o.id);
          if (firstFetchRef.current) {
            firstFetchRef.current = false;
            setLastOrderIds(orderIds);
            return;
          }
          const newNewOrderIds = orderIds.filter((id) => !lastOrderIds.includes(id));
          if (newNewOrderIds.length) {
            soundRef.current.muted = false;
            soundRef.current.play();
            api.info({
              message: `${newNewOrderIds.length} ${newNewOrderIds.length > 1 ? 'new orders!' : 'new order!'}`,
              onClick: () => navigate('/console/orders'),
            });
            setNewOrderIds((ids) => [...ids, ...newNewOrderIds]);
          }
          setLastOrderIds(orderIds);
        });
    },
    hasOrderAccess ? 1000 * 60 * 1 : null,
  );

  useInterval(
    () => {
      getOrders({
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ordering: '-is_new,-timestamp',
        state__in: [
          orderStatus.NEW,
          isAdmin && orderStatus.NEEDS_APPROVAL,
          orderStatus.PAYMENT_SCHEDULED,
        ].filter(isOrderStatus),
        only_scheduled: 1,
        page: 1,
        page_size: 5,
      })
        .then(({ data: { data } }) => {
          const { results } = data;
          const orderIds = results.map((o) => o.id);
          if (firstScheduledFetchRef.current) {
            firstScheduledFetchRef.current = false;
            setLastScheduledOrderIds(orderIds);
            return;
          }
          const newScheduledOrderIds = orderIds.filter((id) => !lastScheduledOrderIds.includes(id));
          if (newScheduledOrderIds.length) {
            api.info({
              message: `${newScheduledOrderIds.length} ${newScheduledOrderIds.length > 1 ? 'new scheduled orders!' : 'new scheduled order!'}`,
              onClick: () => navigate('/console/orders'),
            });
            setScheduledNewOrderIds((ids) => [...ids, ...newScheduledOrderIds]);
          }
          setLastScheduledOrderIds(orderIds);
        });
    },
    hasOrderAccess ? 1000 * 60 * 1 : null,
  );

  const contextValue = useMemo(() => ({
    newOrderIds,
    setSeen,
    newScheduledOrderIds,
  }), [newOrderIds, setSeen, newScheduledOrderIds]);

  return (
    <NewOrdersContext.Provider value={contextValue}>
      {contextHolder}
      <audio ref={soundRef} style={{ display: 'none' }} muted>
        <source src={sound} type="audio/mp3" />
      </audio>
      {children}
    </NewOrdersContext.Provider>
  );
}

export default NewOrdersProvider;
